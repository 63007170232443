<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style ml-2 pl-2 pr-2 new-color-export-facture"
      title="Télecharger pdf facture "
      v-b-modal.extract-modal-obligee-facture-pdf
    >
      <font-awesome-icon icon="file"
    /></b-button>
    <!-- Extract OBLIGEE FACTURE Modal PDF -->
    <b-modal
      ref="extract-modal-obligee-facture-pdf"
      id="extract-modal-obligee-facture-pdf"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Générer Pdf'"
      @hidden="$refs['extract-modal-obligee-facture-pdf'].hide()"
      modal-class="modal-extaction-bdd-frais"
    >
      <b-form-group label="Type de facture:" class="input-modal-champ">
        <b-form-checkbox
          v-model="checkAllType"
          @change="changeAllCheckTypes"
          class="check-th-details"
          >Tous</b-form-checkbox
        >
        <b-form-checkbox-group
          class="check-th-details"
          id="checkbox-group-1"
          v-model="type"
          :options="getTypeListFactureObligee"
          name="flavour-1"
          stacked
          value-field="value"
          text-field="text"
          @change="
            type.length == getTypeListFactureObligee.length
              ? (checkAllType = true)
              : (checkAllType = false)
          "
        ></b-form-checkbox-group>
      </b-form-group>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError" v-if="error">
          <div class="error text-center">{{ error }}</div>
        </div>
        <div class="actionModel">
          <b-button class="button-valide-style " @click="handleExport">
            <span>
              Générer
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Extract OBLIGEE FACTURE Modal  PDF-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {},
  data() {
    return {
      checkAllType: true,
      type: [],
      error: null
    };
  },
  methods: {
    handleExport() {
      this.error = null;
      if (this.type.length == 0) {
        this.error = 'Il faut sélectionner au moins un type';
      } else {
        this.$emit('exportFacturePdf', this.type);
        if (this.$refs['extract-modal-obligee-facture-pdf']) {
          this.$refs['extract-modal-obligee-facture-pdf']?.hide();
        }
      }
    },
    changeAllCheckTypes() {
      this.type =
        this.checkAllType == true
          ? this.getTypeListFactureObligee.map(item => item.value)
          : [];
    }
  },
  computed: {
    ...mapGetters([
      'chargementAppelPaiementThObligeeExportXlsFacture',
      'errorAppeliPaiementThObligeeExportXls',
      'getTypeListFactureObligee'
    ])
  },
  mounted() {
    this.type = this.getTypeListFactureObligee.map(item => item.value);
  }
};
</script>

<style scoped lang="scss">
.new-color-export-facture {
  background-color: #0e0c5e;
}
</style>
